// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationAR from '../src/locales/ar/translation.json';

const resources = {
 /*  en: {
    translation: translationEN
  }, */
  ar: {
    translation: translationAR
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ar', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
