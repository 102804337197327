// HeaderImgs.js
import img1 from '../ASSETS/header1.jpg';
import img2 from '../ASSETS/header2.jpg';
import img3 from '../ASSETS/header3.jpg';
import img4 from '../ASSETS/header4.jpg';
import img7 from '../ASSETS/header7.jpg';
import img6 from '../ASSETS/header6.jpg';
import img10 from '../ASSETS/header10.jpg';
import img8 from '../ASSETS/header8.jpg';
import img9 from '../ASSETS/header9.jpg';
import img11 from '../ASSETS/blog1.jpg'
export const header1 = img1; // Export as a string (image path)
export const header2 = img2;
export const header3 = img3;
export const header4 = img4;
export const header5 = img7;
export const header6 = img6;
export const header7 = img8;
export const header8 = img9;
export const header9 = img10;
export const header10 = img11;